import MessageMap from '../i18n.d';

const SKU_PRIOTIRY_FROM_TO = 'Priority {from} to {to}';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available'
  },
  // NEED COPYDECK FOR DYNAMIC VALIDATIONERRORS
  DynamicComponents: {
    ValidationErrors: {
      Required: 'This field is required.',
      IsChecked: 'This field should be checked.',
      MinLength: 'Must have at least {value} characters.',
      MinValue: 'Must be a value greater than {value}.',
      MaxValue: 'Must be a value smaller than {value}.',
      MaxValueBasedOnField: 'Must be a value smaller than {value} ("{field}" field).',
      MinValueBasedOnField: '{primary_field} must be a value greater than or equal to {field}.',
      AlphaNumeric: 'The value must be alphanumeric. Only letters, numbers and dash are allowed.'
    },
    OptionalLabel: '(optional)',
    Components: {
      budgetId: {
        Label: 'Budget ID',
        Placeholder: 'Enter Budget ID code'
      },
      title: {
        Label: 'Title',
        Subtitle: 'This title will be displayed to customers.',
        Placeholder: 'Enter title text'
      },
      titleInput: {
        Label: 'Title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      startDateAndEndDate: {
        Label: 'Touchpoint date range',
        Placeholder: 'Enter the period'
      },
      description: {
        Label: 'Description',
        Subtitle: 'This description will be displayed to customers.',
        Placeholder: 'Enter a description'
      },
      allocation: {
        Label: 'Allocation',
        Tooltip:
          'The percentage of top-recommended POCs within the selected audience to receive the discount.',
        Placeholder: 'Enter allocation percentage'
      },
      pointsValue: {
        Label: 'Points',
        Placeholder: 'Enter the number of points'
      },
      rewardsCampaignId: {
        Label: 'Campaign group ID',
        Placeholder: 'Enter a Campaign group ID',
        Tooltip:
          'Use this field to associate this touchpoint with any other campaigns you may be running on other platforms.'
      },
      skuTable: {
        Label: 'SKU Table',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      eligibleOrderMaximum: {
        Label: 'Maximum orders per POC',
        Tooltip:
          'The maximum number of eligible orders that each POC can place during the promotion.',
        Placeholder: 'Enter a number'
      },
      skuLimit: {
        Label: 'Maximum SKUs per POC',
        Tooltip: 'The maximum quantity of each SKU that a POC can purchase.',
        Placeholder: 'Enter a number'
      },

      discountAndQuantities: {
        errors: {
          minQtyMessageError: 'Min. quantity must be greater than or equal to 1.',
          maxQtyMessageError: 'Max. quantity must be greater than or equal to min. quantity.',
          discountMessageError: 'Discount must be greater than or equal to 1.',
          minDiscountMessageError: 'Min. discount must be greater than or equal to 1.',
          maxDiscountMessageError: 'Max. discount must be greater than or equal to min. discount.',
          discountTieredMessageError: 'Discount must be greater than or equal to the previous tier.'
        },
        type: {
          nonPersonalized: {
            Label: 'Discounts and quantities',
            Subtitle: 'The values below will be applied to all selected SKUs.'
          },
          personalized: {
            Label: 'Discounts and quantities',
            Subtitle:
              'Fill in the fields below so that our algorithm can recommend the best discounts for each POC.'
          },
          tieredNonPersonalized: {
            Label: 'Discounts and quantities',
            Subtitle: 'The values below will be applied to all selected SKUs.'
          },
          tieredPersonalized: {
            Label: 'Discounts and quantities',
            Subtitle:
              'Fill in at least 2 tiers below so that our algorithm can recommend the best discounts for each POC.'
          }
        },
        minQuantity: {
          Label: 'Min. quantity per order',
          Placeholder: 'Enter a number'
        },
        maxQuantity: {
          Label: 'Max. quantity per order',
          Placeholder: 'Enter a number'
        },
        minDiscount: {
          Label: 'Min. discount',
          Placeholder: 'Enter discount percentage'
        },
        maxDiscount: {
          Label: 'Max. discount',
          Placeholder: 'Enter discount percentage'
        },
        fixDiscount: {
          Label: 'Discount',
          Placeholder: 'Enter discount percentage'
        },
        // Tooltip: "I'm a tooltip :D",
        // Placeholder: 'Enter a number',
        // Discount: 'Discount',
        // MinQuantity: 'Min. quantity per order',
        // MaxQuantity: 'Max. quantity per order',
        // Discount_Placeholder: 'Enter discount percentage',
        // MinDiscount: 'Min. discount',
        // MaxDiscount: 'Max. discount',
        // This is just a tierTable copydeck example, you can change the name of the fields to match your needs
        tierTable: {
          AddTierButton: 'Add tier',
          Title: 'Tier',
          MinQuantity: 'Min. quantity',
          MaxQuantity: 'Max. quantity',
          Discount: 'Discount %',
          // missing copydeck Actions
          Actions: 'Remove'
        }
      },

      Completo: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      testingNumber: {
        Label: 'Testing value input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMaxField: {
        Label: 'Max input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      limitMinField: {
        Label: 'Min input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      TEST: {
        Label: 'Test input',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      bannerTitle: {
        Label: 'Banner title',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      numberOfUnits: {
        Label: 'Units',
        Subtitle: 'The number of units required to complete the offer.',
        Placeholder: 'Enter a number of units'
      },
      dateField: {
        Label: 'Date Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      },
      checkboxField: {
        Label: 'Checkbox Field',
        Subtitle: "I'm a subtitle :D",
        Tooltip: "I'm a tooltip :D",
        Placeholder: "I'm a placeholder :D"
      }
    }
  },

  Components: {
    DatePicker: {
      Label: 'Touchpoint date range',
      Placeholder: 'Enter the period',
      Error: 'Enter a new period.'
    },
    SkuLimit: {
      Label: 'Maximum SKUs per POC',
      Tooltip: 'The maximum quantity of each SKU that a POC can purchase.',
      Placeholder: 'Enter a number'
    }
  },
  Breadcrumb: {
    CampaignList: 'Offer list',
    NewCampaign: 'Create offer',
    CampaignDetails: 'Offer details'
  },
  NewCampaign: {
    Heading: 'Create offer',
    BreadcrumbLabel: 'Create new campaign',
    SubHeading: 'Strategy',
    Info: 'Select a strategy and substrategy. This information will potentialize your campaign´s efficiency.',
    Cancel: 'Cancel',
    Create: 'Create',
    CampaignsTitle: 'Campaigns',
    CampaignPageTitle: 'Offer list'
  },
  countryChangeConfirmationModal: {
    Title: 'Change country?',
    Description: 'If you change the selected country, all unsaved changes will be lost.',
    Cancel: 'No, go back',
    Confirm: 'Yes, change'
  },
  vendorChangeConfirmationModal: {
    Title: 'Change vendor?',
    Description: 'If you change the selected vendor, all unsaved changes will be lost.',
    Cancel: 'No, go back',
    Confirm: 'Yes, change'
  },
  sellerChangeConfirmationModal: {
    Title: 'Change seller?',
    Description: 'If you change the selected seller, all unsaved changes will be lost.',
    Cancel: 'No, go back',
    Confirm: 'Yes, change'
  },
  HomePage: {
    Levers: {
      Title: 'Offer list',
      Table: {
        Name: 'Offer name',
        Category: 'Category',
        Touchpoint: 'Touchpoint types',
        CampaignId: 'Offer ID',
        Period: 'Period',
        Status: 'Status',
        Challenge: 'Challenge',
        Promotion: 'Promotion',
        DigitalCommunication: 'Digital communication',
        PageSizeOptions: 'Items per page: {options}',
        QuantityIndicator: 'of'
      }
    }
  },
  StrategyCard: {
    Strategies: {
      INTRODUCE: 'Introduce',
      SUSTAIN: 'Sustain',
      GENERATE_DEMAND: 'Generate demand',
      OPERATIONS: 'Operations'
    },
    StrategyDescription: {
      INTRODUCE: 'Boost coverage of new or existing products',
      SUSTAIN: 'Encourage repeat purchases and increase purchase frequency.',
      GENERATE_DEMAND: 'Increase purchases strategically.',
      OPERATIONS: 'Drive purchases to manage everyday operations and meet routine targets.'
    },
    SelectLabel: 'Select substrategy',
    SubStrategies: {
      I1: 'I1: Attract customers to try new products',
      I2: 'I2: Improve coverage of existing products',
      I3: 'I3: B2B Welcome offer',
      S1: 'S1: Encourage customers to repeat purchases',
      G1: 'G1: Drive foot traffic through consumer events = promotion',
      G2: 'G2: Encourage POCs to maintain everyday price adherence',
      G3: 'G3: Increase consumption through passing lower price to consumers',
      G4: 'G4: Optimize assortment at POCs',
      G5: 'G5: Negotiate feature, display, shelf or planogram placement',
      G6: 'G6: Trade program - discount per target achievement',
      G7: 'G7: Generate demand on low-selling or close-to-expiration SKUs to reduce supply-chain losseste demand on low-selling or close to expiration SKUs',
      O1: 'O1: Leverage promos to hit monthly/quarterly target',
      O2: 'O2: Respond to market dynamics',
      O3: 'O3: Payment related discount (e.g. cash discount)',
      O4: 'O4: Reduce distribution burden (e.g. anticipated occasion-based demand spikes, distribute to KA warehouses etc.) occasion-based',
      O5: 'O5: Generate demand on low-selling or to reduce supply-chain losses',
      O6: 'O6: Others (employee discount, rewards, etc.)'
    }
  },
  chipCopywrite: {
    ddc: '{value}',
    'sales-uen': '{value}',
    'sales-state': '{value}',
    'sales-region-drv': '{value}',
    'sales-region-geo': '{value}',
    'digital-order-since-comparison': '{operator} {value, plural, one {# day} other {# days}}',
    'digital-order-since': 'Last {value, plural, one {# day} other {# days}}',
    'engagement-score': '{value}',
    'orders-count': '{operator} {value}',
    'purchased-marketplace-count': '{operator} {value}',
    'purchased-sku-count': '{operator} {operatorValue}: {value}',
    'sku-priority-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-introduce-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-sustain-range-id': SKU_PRIOTIRY_FROM_TO,
    'sku-priority-generate-range-id': SKU_PRIOTIRY_FROM_TO,
    'service-model': '{model}: {vendor}',
    label: {
      ddc: 'Bought from DDC',
      'sales-uen': 'UEN',
      'sales-state': 'State',
      'sales-region-drv': 'Sales region',
      'sales-region-geo': 'Sales region',
      'digital-order-since-comparison': 'Last ordered in',
      'digital-order-since': 'Last ordered in',
      'engagement-score': 'An engagement score',
      'orders-count': 'Ordered quantity',
      'purchased-marketplace-count': 'Purchased marketplace',
      'purchased-sku-count': 'Purchased SKU',
      'sku-priority-range-id': 'SKU prioritization',
      'sku-priority-introduce-range-id': 'Introduce',
      'sku-priority-sustain-range-id': 'Sustain',
      'sku-priority-generate-range-id': 'Generate',
      'service-model': 'Service model'
    }
  },
  operators: {
    greaterThan: 'More than',
    lowerThan: 'Less than',
    equals: 'Equal to',
    yes: 'Yes',
    no: 'No'
  },
  Expressions: {
    High: 'High',
    Medium: 'Medium',
    Low: 'Low'
  },
  SettingsPage: {
    Title: 'Settings',
    Description: 'Add name, period, and SKUs.',
    DetailsCard: {
      Title: 'Settings',
      Name: 'Offer name',
      Error: 'Name must have at least 3 characters.',
      ErrorNameUsed: 'Campaign name already in use. Try another one.',
      Period: {
        Label: 'Period',
        Placeholder: 'Enter period',
        Error: 'Enter a new period.'
      }
    },
    StrategyComponent: {
      Strategy: 'Strategy',
      Substrategy: 'Substrategy'
    },
    SkuCard: {
      Title: 'SKUs',
      Product: 'Product',
      Code: 'SKU Code',
      Remove: 'Remove',
      AddSKU: 'Select SKUs'
    },
    EmptySku: {
      Message:
        'No SKUs selected yet. This offer type requires that you add at least one SKU to proceed.',
      Action: 'Select SKUs'
    },
    ModalEditSku: {
      Title: 'Edit campaign date range?',
      Subtitle: 'This action will reset all the dates set within the touchpoints.',
      Action: 'Yes, edit',
      Cancel: 'No, cancel'
    },
    ModalAddSku: {
      Title: 'Add SKUs?',
      Subtitle: 'This will reset the selected SKUs within the touchpoints.',
      Action: 'Yes, add',
      Cancel: 'No, cancel'
    },
    ModalRemoveSku: {
      Title: 'Remove SKUs?',
      Subtitle: 'This will reset the selected SKUs within the touchpoints.',
      Action: 'Yes, remove',
      Cancel: 'No, cancel'
    },
    ModalAddSKUPopModel: {
      Title: 'Add SKUs?',
      Subtitle:
        'This will change the Smart audience estimate and reset any selected SKUs within touchpoints details.',
      Action: 'Yes, add',
      Cancel: 'No, cancel'
    },
    ModalRemoveSKUPopModel: {
      Title: 'Remove SKUs?',
      Subtitle:
        'This will change the Smart audience estimate and reset any selected SKUs within touchpoints details.',
      Action: 'Yes, remove',
      Cancel: 'No, cancel'
    },
    Alert:
      'The running dates for this campaign have passed. Select a new period to publish this draft.'
  },
  AudiencePage: {
    Title: 'Audience',
    Description: 'Specify who will be impacted.',
    warningText:
      'Smart audience is activated, so the estimated number of POCs targeted might change. The new estimate with the suggested POCs for this offer will be displayed on the Summary page.'
  },
  TouchpointsPage: {
    Touchpoints: 'Touchpoints',
    Title: 'Touchpoints',
    Description: 'Pick a type of interaction.',
    TouchpointCategory: {
      POINTS_BASED: 'Rewards challenge',
      Promotion: 'Promotions'
    },
    Modal: {
      Title: 'Select touchpoint',
      Search: 'Search by category or touchpoint name',
      Action: 'Select touchpoint',
      Cancel: 'Go back',
      Results: 'results',
      NoResultsFound: 'No results found. Try changing your search',
      ClearSearch: 'Clear search'
    },
    EmptyStateCard: {
      Title: 'No touchpoint selected yet',
      Subtitle: 'The touchpoint you select will appear here.',
      ButtonLabel: 'Select touchpoint'
    },
    EditButton: 'Edit touchpoint',
    editDialog: {
      Title: 'Edit touchpoint?',
      Subtitle: 'This will reset all the details you might have added to this touchpoint.',
      Action: 'Yes, edit',
      Cancel: 'No, go back'
    },
    RemoveModal: {
      Title: 'Remove touchpoint?',
      Subtitle: 'This will delete the touchpoint and all of its details.',
      Action: 'Yes, remove',
      Cancel: 'No, go back'
    }
  },
  TouchpointDetailsPage: {
    TouchpointType: 'Multiple order',
    errors: {
      title: 'Please fix the following errors:',
      description: 'You must add a quantity for each SKU that has been selected',
      pointsValue: 'Points value must be greater than #param',
      numberOfUnits: 'Number of units must be greater than #param'
    },
    Title: 'Touchpoint details',
    Card: {
      Title: 'SKU quantity'
    },
    Description: 'Set up the information.',
    SelectedAudienceCard: {
      Title: 'Selected audience'
    },
    SkuCard: {
      AlertText:
        'Selecting Single SKU will create multiple challenges with the same content and points.',
      SingleSkuTitle: 'Single SKU',
      SingleSkuDescription: 'The POC receives multiple challenges with 1 SKU each.',
      MultipleSkuTitle: 'Multiple SKUs',
      MultipleSkuDescription: 'The POC receives 1 challenge with all the SKUs selected below.',
      ChallengeskuLabel: 'Challenge display'
    },
    AudienceSelectedItem: {
      Trait: 'trait',
      Traits: 'traits',
      CSVFile: 'CSV file',
      TextButton: 'Show all',
      TraitBox: 'Traits'
    },
    DetailsCard: {
      Title: 'Touchpoint details',
      EmptyQuantity: {
        Message:
          'No quantity has been set.{br} You must add a quantity for each SKU in the challenge.',
        Action: 'Set quantity'
      },
      CoverImage: {
        message1: 'File must be JPG or PNG format and',
        message2: 'up to 1MB in size.',
        error: 'File size or format is not valid. Try uploading again.',
        button: 'Select file',
        label: 'Cover image'
      },
      Badge: {
        completed: 'Completed',
        pending: 'Pending'
      },
      ItemCard: {
        POINTS_BASED: 'Rewards challenge',
        POINTS_BASED_MULTIPLE_ORDER: 'Multiple order',
        POINTS_BASED_VOLUME_FIXED: 'Volume-fixed'
      },
      DatePicker: {
        Label: 'Challenge date range'
      },
      InputTitle: {
        Label: 'Title',
        Placeholder: ''
      },
      InputDescription: {
        Label: 'Description',
        Placeholder: 'Enter a description'
      },
      InputPoints: {
        Label: 'Points',
        Placeholder: 'Enter the number of points'
      },
      InputCampaignGroupId: {
        Label: 'Campaign group ID',
        Placeholder: ' ',
        Optional: '(optional)',
        Tooltip:
          'Use this field to associate this touchpoint with any other campaigns you may be running on other platforms.'
      },
      InputUnits: {
        Label: 'Units',
        Hint: 'The number of units required to complete the offer.',
        Placeholder: 'Enter the number of units'
      },
      SKUQuantityTable: {
        Title: 'SKU quantity',
        EditQuantity: 'Edit quantity',
        Product: 'Product',
        SKUCode: 'SKU Code',
        Quantity: 'Quantity',
        RewardsChallengeId: 'Rewards challenge ID'
      },
      ValidationErrors: {
        minLength: 'Must have at least {value} characters.',
        minValue: 'Must be a value greater than {value}.'
      },
      SkuRemoveTable: {
        Title: 'SKUs',
        AddSKU: 'Add SKUs',
        EditSKU: 'Edit SKUs',
        EmptyQuantity: {
          Message: 'No SKUs have been added. You must add at least one SKU to proceed.'
        }
      }
    },
    SKUQuantity: {
      Dialog: {
        Title: 'Set quantity',
        Cancel_Button: 'Cancel',
        Apply_Button: 'Apply'
      }
    },
    MultipleOrder: {
      Title: 'Multiple order',
      Description:
        'A challenge that allow POCs to earn points by purchasing the required SKUs within a certain time fame.'
    }
  },
  SummaryPage: {
    Title: 'Summary',
    Description: 'Review and submit.',
    SKUSettingsCardTable: {
      Tooltip: 'No touchpoint associated.'
    },
    Alert:
      'Some SKUs will not be included in this offer because they are not associated with touchpoints. When Smart audience is activated, this might change the estimated number of POCs targeted.',
    AlertButton: 'Show SKUs',
    SkusWithoutTouchpointsModal: {
      Title: 'SKUs without touchpoints',
      Subtitle:
        'You must associate these SKUs with touchpoints if you want to include them in this offer. Otherwise, they will not be included. Go to the Touchpoints details page to associate them with a touchpoint.',
      Paragraph: 'To add these SKUs to a touchpoint, go to Touchpoints details.',
      ConfirmMessage: 'Go to Touchpoint details',
      CancelMessage: 'Close'
    },
    PopModelAlert: {
      Failed:
        'No estimated POCs were targeted after Smart audience activation. Review SKUs or choose another audience.',
      Processed: 'The number of POCs targeted has changed due to Smart audience activation.',
      Processing:
        'The number of estimated POCs targeted is in progress due to Smart audience activation. It will be automatically updated when it is done.'
    },
    SKUTable: {
      Title: 'SKUs'
    },
    DiscountItemsStepsTable: {
      Title: 'Discounts and quantities',
      Tier: 'Tier',
      MinQuantity: 'Min. quantity',
      MaxQuantity: 'Max. quantity',
      FixDiscount: 'Discount %'
    }
  },
  StepActions: {
    Next: 'Next',
    Back: 'Go back',
    SaveAndExit: 'Save and exit',
    Publish: 'Publish'
  },
  AudienceCard: {
    Title: 'Audience',
    EditButton: 'Edit',
    AudienceName: 'Audience name',
    EstimatedPOCsTargeted: 'Estimated POCs targeted',
    Traits: 'Traits',
    ShowAll: 'Show all',
    MoreTraits: '{value} traits',
    OneTrait: '{value} trait',
    CSVUploaded: 'CSV uploaded'
  },
  LeaveDialog: {
    Title: 'Leave offer creation?',
    Description: 'All unsaved changes will be lost.',
    Cancel: 'No, go back',
    CancelOnSideBar: 'No, continue creation',
    Leave: 'Yes, leave'
  },
  DetailsPage: {
    Title: 'Offer details',
    PublishedBy: 'Published by',
    PublishersEmail: "Publisher's email",
    PublishedAt: 'Published at',
    publishedTime: 'at'
  },
  StatusCode: {
    DRAFT: 'Draft',
    PREPARING_LEVER: 'In preparation',
    SCHEDULED: 'Scheduled',
    ACTIVE: 'Active',
    ENDED: 'Ended',
    ERROR: 'Error'
  },
  SettingsCard: {
    Title: 'Settings',
    EditButton: 'Edit',
    OfferName: 'Offer name',
    OfferId: 'Offer ID',
    Period: 'Period',
    PeriodSeparator: 'to',
    Status: 'Status',
    Skus: 'SKUs'
  },
  TouchpointsVisibilityCard: {
    Touchpoints: 'Touchpoints',
    TouchpointDetails: 'Touchpoint details',
    ChallengeDateRange: 'Challenge date range',
    ChallengeDisplay: 'Challenge display',
    CollumGridtitle: 'Title',
    CampaignGroupId: 'Campaign group ID (optional)',
    Description: 'Description',
    Allocation: 'Allocation',
    Points: 'Points',
    CoverImage: 'Cover image',
    EditButton: 'Edit',
    RewardsCampaignId: 'Rewards challenge ID',
    Units: 'Units',
    OfferDateRange: 'Offer date range',
    DiscountDateRange: 'Touchpoint date range',
    BudgetId: 'Budget ID',
    Eligible: 'Maximum orders per POC',
    SkuLimit: 'Maximum SKUs per POC',
    DiscountAndQuantitiesFields: {
      Min: 'Min. quantity',
      Max: 'Max. quantity',
      Discount: 'Discount',
      MinDiscount: 'Min. discount',
      MaxDiscount: 'Max. discount'
    },
    TableDiscountAndQuantities: {
      Tier: 'Tier',
      Min: 'Min. quantity',
      Max: 'Max. quantity',
      Discount: 'Discount %',
      Actions: 'Remove',
      EmptyMessage: 'No discounts added yet.',
      ButtonAddTier: 'Add tier'
    }
  },
  Touchpoints: {
    Category: {
      POINTS_BASED: 'Point-based offers',
      POINTS_BASED_MULTIPLE_ORDER: 'Point-based offers',
      POINTS_BASED_VOLUME_FIXED: 'Point-based offers',
      PROMOTIONS: 'Promotions',
      PROMOTIONS_PERSONALIZED: 'Personalized promotions',
      PROMOTIONS_DISCOUNT: 'Promotions',
      PROMOTIONS_TIERED_DISCOUNT: 'Promotions',
      REWARDS_CHALLENGE: 'Point-based offers',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Point-based offers'
    },
    Type: {
      POINTS_BASED_MULTIPLE_ORDER: 'Multiple order',
      POINTS_BASED_VOLUME_FIXED: 'Volume-fixed',
      SINGLE_PURCHASE: 'Single order',
      DISCOUNTS: 'Discount',
      PROMOTIONS_TIERED_DISCOUNT: 'Tiered discount',
      STEPPED_DISCOUNTS: 'Stepped discount',
      INTERACTIVE_COMBOS: 'Interactive combos',
      FIXED_COMBOS: 'Fixed combos',
      SPEND_BASED: 'Spend based',
      VOLUME_FIXED: 'Volume fixed',
      PROMOTIONS_DISCOUNT: 'Discount',
      PROMOTIONS_DISCOUNT_QUANTITY: 'Discounts and quantities',
      REWARDS_CHALLENGE: 'Multiple order',
      REWARDS_CHALLENGE_MULTIPLE_ORDER: 'Multiple order'
    },
    TouchpointsDescriptions: {
      POINTS_BASED_MULTIPLE_ORDER:
        'An offer that allows POCs to earn points by purchasing the required SKUs in multiple orders within a set time frame.',
      SINGLE_PURCHASE:
        'Specific purchase quantity per SKU that can be purchased in one single order within the challenge period.',
      POINTS_BASED_VOLUME_FIXED:
        'An offer that allows POCs to earn points by purchasing a required number of SKUs within a set time frame.',
      SPEND_BASED:
        'Overall purchase value ($$), the user can of which SKU from the challenge SKU list, that can be purchased in multiple orders within the challenge period.',
      DISCOUNTS:
        'Minimum and Maximum purchase quantity per SKU, that every time the customers reaches that quantity in an order within the promotion period they will get the benefit, but only for a limited quantity.',
      PROMOTIONS_TIERED_DISCOUNT:
        'POCs who reach the required quantity within the promotion period will receive a discount that increases as the POCs purchase more products, with each tier offering a higher discount than the previous one.',
      STEPPED_DISCOUNTS:
        'Specific minimum purchase quantity per SKUs and specific benefit per range of purchase quantity, every time the customers reaches a specific range minimum purchase quantity the benefit will increase.',
      FIXED_COMBOS:
        'Specific minimum purchase quantity per SKU that can be purchased in one single order within the promotion period.',
      INTERACTIVE_COMBOS:
        'Overall purchase quantity cross SKU or Minimum and Maximum purchase quantity per SKU that can be purchased in one single order within the promotion period.',
      PROMOTIONS_DISCOUNT:
        'POCs who reach the required quantity within the promotion period will receive a discount, but only for a limited quantity.',
      PROMOTIONS_DISCOUNT_QUANTITY: 'The values below will be applied to all selected SKUs.'
    }
  },
  Toast: {
    Success: 'Campaign created',
    Error: 'Something went wrong. Try again.'
  },
  SideBar: {
    AudienceBuilder: 'Audience builder'
  },
  Countries: {
    BR: 'Brazil',
    MX: 'Mexico',
    CO: 'Colombia',
    AR: 'Argentina',
    ZA: 'South Africa'
  }
};

export default enUS;
